@use '../shared/shared.scss';

.App {
  text-align: center;
  background-color: shared.$color-bg-main;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.cog-icon {
  font-size: 40vmin;
  color: shared.$color-text-secondary;
}

@media (prefers-reduced-motion: no-preference) {
  .cog-icon {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-main {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: shared.$color-text-main;
  gap: 2vmin;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.first-letter:first-letter {
  color: shared.$color-text-secondary;
}
