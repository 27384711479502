@use "src/shared/shared";

.skill-bar {
  display: flex;
  flex-direction: column;
  gap: 0.5vmin;
  width: 100%;
  max-width: 600px;
}

.skill-bar-text {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 1.5vmin;
  font-size: max(15px, 2.5vmin);
}

.skill-bar-description {
  font-size: max(15px, 2vmin);
  font-weight: lighter;
}

//.skill-bar-name {
//  text-decoration: underline shared.$color-text-secondary;
//}

//.nextui-progress-bar {
//  background-color: shared.$color-bg-secondary; // TODO
//}