@use "src/shared/shared";

.name-image-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1vmin;
}

.name-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.name {
  font-size: max(25px, 15vmin);

  .first-letter {
    color: shared.$color-text-secondary;
  }
}

.name-title {
  font-size: max(10px, 5vmin);
}

.button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 1vmin;
  width: 100%;
}

.link-container {
  min-width: 35vmin;
}

.link-next {
  letter-spacing: 0;
  margin: 1vmin;
  font-size: max(15px, 3vmin);
  color: shared.$color-text-main;
  text-decoration: none;
  transition: all 300ms ease-in-out;
}

.link-next:hover,
.link-next:active {
  letter-spacing: 2px;
  color: shared.$color-text-secondary;
  transition: all 300ms ease-in-out;
}


@media (prefers-reduced-motion: no-preference) {
  .name-title {
    position: relative;
    animation: Carousel infinite 5s linear;
    animation-delay: 0.75s;
  }
}


@keyframes Carousel {
  0%, 70% {
    right: 0px;
    opacity: 100%;
  }
  85% {
    right: 10vmin;
    opacity: 0%
  }
  86% {
    right: -10vmin;
    opacity: 0%
  }
  100% {
    right: 0px;
    opacity: 100%
  }
}