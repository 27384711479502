@use "../../shared/shared";

.navbar {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  flex-wrap: wrap;

  .logo-container, .filler {
    min-width: 15vmin;
    margin: 1vmin;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1vmin;
  }

  .logo-container {
    font-size: calc(15px + 2vmin);
  }

  .filler {
    font-size: max(10px, 2vmin);
  }

  .logo {
    height: calc(15px + 3vmin);
  }


  .navbar-item-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .navbar-item {
    letter-spacing: 0;
    min-width: 15vmin;
    margin: 1vmin;
    font-size: max(15px, 2vmin);
    color: shared.$color-text-main;
    text-decoration: none;
    transition: all 300ms ease-in-out;
  }

  .navbar-item-selected,
  .navbar-item:hover,
  .navbar-item:active {
    letter-spacing: 2px;
    color: shared.$color-text-secondary;
    transition: all 300ms ease-in-out;
  }
}
