@use "src/shared/shared";

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 45px;
  width: 50%;
  background-color: white;
  padding: 45px;
  border-radius: 45px;

  & > button {
    align-self: flex-end;
  }
}

.contact-form-top {
  display: flex;
  flex-direction: row;
  gap: 45px;
  width: 100%;

  & > * {
    flex-grow: 1;
  }
}