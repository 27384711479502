@use "src/shared/shared";

.whoami-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  gap: 4vmin;
  margin: max(10px, 5vmin);
  flex-grow: 1;
}

.bio-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 4vmin;
}

.skills-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 4vmin;
  flex-grow: 1;
  width: 100%;
}

.technical-skills, .other-skills, .language-skills, .soft-skills {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  gap: 1.5vmin;
}

.technical-skills, .other-skills {
  width: calc(50% - 2vmin);
}

.language-skills, .soft-skills {
  width: 100%;
}

.soft-skills-items {
  text-align: left;
  font-size: max(15px, 2.5vmin);
}

.info-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.5vmin;
  width: max(50px, 35vmin);
}

.description-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1000px;
  min-width: 300px;
}

.short-description, .description {
  text-align: left;
  margin: 0;
}

.description {
  font-size: max(15px, 2vmin);
}

.short-description {
  font-size: max(15px, 4vmin);
}

.download-button-container {
  margin-top: 3vmin;
  align-self: center;
}

.skills-title {
  font-weight: bold;
}