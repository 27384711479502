@use "src/shared/shared";

.info-line {
  display: flex;
  flex-direction: row;
  gap: 1.5vmin;
  font-size: max(10px, 2vmin);
}

.info-value {
  font-weight: lighter;
}

.info-name {
  text-decoration: underline shared.$color-text-secondary;
}